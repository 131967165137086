
#services {
    background-color: white;
    color: black;

    h1 {
        text-align: center;
        padding: 1rem;
        font-weight: 600;
    }

    .jumbo {
        background-color: #EFEFEF;
    }
    h2 {
        text-align: center;
        color: #CA8E4C;
        padding: 1rem;
        font-size: 2em;
        font-weight: 600;
    }

    .booknow {
        display: block;
        background-color: #EFEFEF;
        width: 100%;
        text-align: center;
        img {
            padding: 1rem;
        }
        
    }
}