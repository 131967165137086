

.vertical-center {
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

.car-viewer {
    background: white;
    // font-size: 1.5em;
    text-align: center;
    margin: -2rem;
    color: black;

    h1 {
        padding-top: 2rem;
        font-weight: 500;
    }

    .cars-items {
        display: flex;
        

        .car-item {
            width: 90%;
            margin: 1rem;

            a {
                color: black;
                text-decoration: none;
            }

            .car-item-encart {
                height: 12em;

                img {
                    max-width: 100%;
                    max-height: 220px;
                }        
            }
            p {
                font-size: 1.8em;
                font-weight: 100;
            }
        }
    }

    @media screen and (max-width: 500px) {
       
        .cars-items {
            flex-wrap: wrap;
            margin-bottom: 2rem;

            .cars-item {
                margin: auto;
                img {
                    width: 80%;
                }

                p {
                    font-size: 1.5em;
                    margin: 1rem 1rem;
                }
            }
        }
    }
}