
.to-know {
    background: #EFEFEF;
    text-align: center;
    margin: -1rem;
    margin-bottom: 1rem;

    h1 {
        font-weight: 700;
        color: black;
        padding: 2rem;
        font-size: 1.5em;
    }

    .to-know-boxes {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .to-know-box {
            font-family: 'Poppins';
            background-color: #A98A63;
            color: white;
            margin-bottom: 2rem;
            padding: .5rem;
            font-size: .8em;
            height: 200px;
            
            .icon {
              display: block;
              height: 80px;
                font-size: 4em;
                &.danger::before {
                    font-family: 'font-awesome';
                    content: "\f071";
                }
                &.mail::before {
                    font-family: 'simple-line-icons';
                    content: "\e086";
                }
            }

            img {
              height: 100px;
            }

            p {
              font-weight: 600;
                font-size: 1.4em;
                max-width: 250px;
                margin: 0 auto
            }
        }
    }


    
    img#visa {
      max-width: 80%;
    }
    
  
  @media screen and (min-width: 901px) {
    .to-know-box {
      &.flex3 {
        width: 30%;
      }

      &.flex2 {
          width: 47%;
      }
    }
  }

  @media screen and (max-width: 900px) {
      
    .to-know-box.flex2, .to-know-box.flex3 {
        width: 300px;
        margin: 1rem;
    }
  }

  
  @media screen and (max-width: 750px) {
      
    .to-know-box.flex2, .to-know-box.flex3 {
        width: 400px;
        margin: 1rem auto;
    }

  }

  @media screen and (max-width: 400px) {
      
    .to-know-box.flex2, .to-know-box.flex3 {
      width: 80%;
    }

  }
}