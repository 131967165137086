.car-detail {
    color: black;

    text-align: center;
    padding: 1rem;
    font-weight: 600;

    img {
        max-width: 100%;
    }
}