
#contact {
    background-color: white;
    color: black;

    h1 {
        text-align: center;
        padding: 1rem;
        font-weight: 600;
    }

    .jumbo {
        background-color: #EFEFEF;
        color: #CA8E4C;
        font-size: 2em;
        &-left, &-right {
            flex: 1;
        }

        .jumbo-right {
            p {
                margin-bottom: 0;
            }
        }

        .footer-phone {
            font-weight: 700;
        }

        .footer-mail {
            font-size: .7em;
            a {
                color: #CA8E4C;
                font-weight: 400;
                text-decoration: none;
                &:hover {
                    color: #7A5B2E;
                }
            }
        }

        .icon {
            font-family: "simple-line-icons";
            &.phone-out::before {
              font-weight: 100;
              content: "\e046";
            }
            &.mail-open::before {
              content: "\e01e";
            }
          }
        
    }

    .booknow {
        display: block;
        background-color: #EFEFEF;
        width: 100%;
        text-align: center;
        img {
            padding: 1rem;
        }
        
    }

    @media screen and (max-width: 700px) {
        .jumbo {
            .flex {
                flex-wrap: wrap;
            }    

            &-right {
                min-width: 400px;
            }

            &-left {
                max-width: 100%;
            }
        }

        .content-width {
            padding: 0rem 1rem;
        }
    }
}