
.trust {
    background: #A98A63;
    text-align: center;
    margin: -1rem;

    h1 {
        font-weight: 700;
        color: white;
        padding: 2rem;
        font-size: 1.5em;
    }

    .trust-boxes {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .trust-box {
            font-family: 'Poppins';
            background-color: white;
            color: black;
            margin-bottom: 2rem;
            padding: .5rem;
            font-size: .8em;
            height: 200px;
            
            .icon {
                font-size: 3em;
                // font-family: 'Merriweather';
                color: #A98A63;
                &.user::before {
                    font-family: 'simple-line-icons';
                    content: "\e005";
                }
                &.watch::before {
                    font-family: 'line-icons';
                    content: "\e01d";
                }
                &.smile::before {
                    font-family: 'et-line';
                    content: "\e05b";
                }
                &.car::before {
                    font-family: 'font-awesome';
                    content: "\f1ba";
                }
                &.clock::before {
                    font-family: 'et-line';
                    content: "\e055";
                }
                }

            h2 {
                font-weight: 600;
            }

            p {
                font-size: 0.8em;
                color: #444;
            }
        }
    }

    
  @media screen and (max-width: 900px) {
      
    .trust-box.flex2, .trust-box.flex3 {
        width: 300px;
        margin: 1rem;
    }
  }

  
  @media screen and (min-width: 901px) {
    .trust-box {
      &.flex3 {
        width: 30%;
      }

      &.flex2 {
          width: 47%;
      }
    }
  }
  
  @media screen and (max-width: 750px) {
      
    .trust-box.flex2, .trust-box.flex3 {
        width: 400px;
        margin: 1rem auto;
    }
  }

  @media screen and (max-width: 500px) {
    
    h1 {
        font-size: 1.2em;
    }

    .trust-box.flex2, .trust-box.flex3 {
        width: 80%;
    }
  }


  @media screen and (max-width: 500px) {

    .content-width {
        padding: 0;
    }
  }
}