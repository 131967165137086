nav {
    background: #262831;// rgba(20,20,55,.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .icon.mail::after {
        font-family: "simple-line-icons";
        content: "\e01e";
      }
      
      .icon.phone::after {
        font-family: "simple-line-icons";
        content: "\e600";
      }
      

    & .navbar {
        display: flex;
        justify-content: space-between;
    
        & a {
            text-decoration: none;
        }
    
        & .icon-bar {
            background-color: white;
            &+.icon-bar {
                margin-top: 4px;
            }
        }

        .icon {
            color: #D9AA72;
        }

        & .navbar-toggle {
            position: relative;
            float: right;
            // padding: 9px 10px;
            margin-top: 3px;
            margin-right: 15px;
            margin-bottom: 8px;
            background-color: transparent;
            background-image: none;
            border: 1px solid transparent;
            border-radius: 4px;
            display: none;
            cursor: pointer;

            & .icon-bar {
                display: block;
                width: 22px;
                height: 2px;
                border-radius: 1px;
            }
        }
    }
    
    & .navbar .navbar-header {
        // background: blue;
        padding: .5rem;
        font-size: 1.2em;

        & a {
            // display: flex;
            padding: 0px;
            font-size: 1em;
            // font-weight: bold;
            & img {
                position: relative;
                top: 2px;
                margin-right: 5px;
            }

            &:hover {
                color: #D9AA72;
            }
        }

        .logo {
            display: flex;
            flex-wrap: wrap;

            .logo_info {
                padding: 1.5rem;
                font-size: .8em;
                .logo_surtaxe {
                    font-size: 0.6em;
                }
            }
    
            img {
                max-height: 80px;
            }    
        }

    }
    
    & .navbar ul {
        // flex: 1 1 auto;
        // background: green;
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        padding: 2rem;
    
        & li {
            margin: 0rem 1rem;
            & a {
                color: #AAA;
                font-weight: bold;
                &:hover, :active, &.selected {
                    color: #FFFFFF;
                }    
            }
        }
    }

    @media screen and (max-width: 975px) {

        & .navbar .navbar-header {
            .logo {
                .logo_info {
                    padding: 0.5rem 1.5rem;
                }    
            }    
        }
    }
    @media screen and (max-width: 650px) {
        & .navbar {
            display: flex;
            flex-wrap: wrap;
            // background: blue;
            & ul {
                // background: green;
                flex-wrap: wrap;
                & li {
                    width: 100%;
                    padding: .5rem;
                    font-size: 1.2em;
                }
            }

            & .hidden {
                display: none;
            }

            & .navbar-toggle {
                display: block;
            }

            .logo_info {
                display:  none;
            }

        }
    }

    @media screen and (max-width: 370px) {

        .navbar {
            img {
                width: 220px;
            }    
        }

    }
}
