$cont-pos: #FFDA7B;
$cont-speed:#FF1D10;
$cont-acc: #FF9554;


@font-face {
  font-family: 'simple-line-icons';
  // src: local('line-icons'), url(../fonts/LineIcons.ttf) format('truetype');
  // font-family: 'simple-line-icons';
  // src: local('simple-line-icons'), url(../fonts/Simple-Line-Icons.ttf) format('truetype');
  src: local('simple-line-icons'), url(../fonts/Simple-Line-Icons.ttf) format('truetype');
  // src: local('simple-line-icons'), url(../fonts/linecons.woff) format('truetype');
}
@font-face {
  font-family: 'line-icons';
  src: local('linecons'), url(../fonts/linecons.woff) format('truetype');
}
@font-face {
  font-family: 'font-awesome';
  src: local('font-awesome'), url(../fonts/fontawesome-webfont.woff) format('truetype');
}
@font-face {
  font-family: 'et-line';
  src: local('et-line'), url(../fonts/et-line.woff) format('truetype');
}

body {
  color: white;
  background: #262831;
}

.color-white {
  color: white;
}

a {
  color: white;
}

#app {
  overflow-x: hidden;
  // margin-bottom: 4rem;
}

#content {
  background-color: white;
  padding-top: 5rem;
}

#home {
/* Eléments principaux de la page */

  & #title {
    display: block;
    text-align: center;
  }

  & #content {

    & #titles {
      font-weight: bold;
      text-align: center;
      font-size: 2.2em;

      & span {
        display: inline-block;
        margin: 0rem 3rem;
        width: 110px;
        text-align: center;
        padding: 2.2rem 0rem;
        border-radius: 4rem;
        font-weight: bold;
      }

      & .head {
        font-weight: bold;
        text-align: left;
        font-size: .6em;
      }

      & span.head {
        padding: 0;
        text-align: center;
      }
      & span.title.head {
        color: transparent;
      }
    }

    & #facteurs {
      text-align: center;
      font-size: 2em;
  
      & span, & div.head {
        display: inline-block;
        margin: .5rem 3rem;
        text-align: center;
        border-radius: 4rem;
        font-weight: bold;
      }

      & span {
        padding: 2.2rem 0rem;
        width: 110px;
      }

      & div.head {
        text-align: left;
        width: 130px;

        & .head__value {
          font-size: .8em;
        }
      }

      & .head {
        font-weight: bold;
        text-align: left;
        font-size: .6em;
      }

      & .position, & .speed, & .acceleration {
        color: transparent;  
      }
    
      & #contaminations {
        & .position {
          background-color: $cont-pos;
        }
  
        & .speed {
          background-color: $cont-speed;
        }
  
        & .acceleration {
          background-color: $cont-acc;
        }
      }
    }

    & .home__note {
      width: 100%;
      text-align: center;

      & p {
        margin: 0 auto;
        max-width: 800px;
        font-size: .8em;
      }

      & a {
        text-decoration: none;
        color: #aaa;
        font-weight: bold;
      }

    }
  }

  @media screen and (max-width: 900px) {

    & #content {
      & #titles {
        width: 100%;
        font-size: .7em;
        & span {
          width: 80px;
          padding: 1.5rem 0rem;
          margin: 0.5rem 1.5rem;
        }
  
        & .head {
          font-size: 1.3em;
          display: inline-block;
          margin: 1.5rem;  
      }  
      }

      & #facteurs {
        font-size: .7em;

        & span {
          width: 80px;
          padding: 1.5rem 0rem;
          margin: 0.5rem 1.5rem;
        }
  
        & .head {
          font-size: 1.3em;
        }  
      }

    }
  }

  @media screen and (max-width: 623px) {

    & #content {
      & #titles {
        margin: 0;
        & .head {
          display: inline-block;
          margin: 1rem 1rem;
          font-size: 1.3em;
        }  
        & span {
          &.title {
            display: none;
          }
        }

      }

      & #facteurs {
        margin: 0;
        font-size: .7em;
        
        & span {
          width: 80px;
          padding: 1.5rem 0rem;
          margin: 0.5rem .8rem;
        }
  
        & div.head {
          display: flex;
          font-size: 1.3em;
          width: 20rem;
          margin: .5rem 2rem;
  
          & p {
            display: block;
            margin: 0rem 1rem;
          }
        }  
      }

    }
  }
}
