
.material {
    background: white;
    // font-size: 1.5em;
    text-align: center;
    margin: -2rem;
    color: black;

    h1 {
        padding-top: 2rem;
        font-weight: 500;
    }

    .materials {
        display: flex;

        .material-item {
            width: 90%;
            margin: 1rem;
            img {
                width: 100%;
            }    
            p {
                font-size: 1.8em;
                font-weight: 100;
            }
        }
    }

    @media screen and (max-width: 500px) {
       
        .materials {
            flex-wrap: wrap;
            margin-bottom: 2rem;

            .material-item {
                margin: auto;
                img {
                    width: 80%;
                }

                p {
                    font-size: 1.5em;
                    margin: 1rem 1rem;
                }
            }
        }
    }
}