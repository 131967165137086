
.booking {
    // margin: 0 auto;
    // width: 820px;
    font-size: 2.5em;
    text-align: center;

    a  {
        text-decoration: none;
    }

    #koffi-transport {
        background-image: url("/images/koffi-transports-monceau-sur-sambre.jpg"); //url("images/koffi-transports-monceau-sur-sambre.jpg");
    }

    #transport-aeroport {
        background-image: url("/images/transport-gares-monceau-sur-sambre.jpg");
    }

    #disponible-24h24-7j7 {
        background-image: url("/images/disponible-monceau-sur-sambre1.jpg");
    }

    .carousel-item {
        padding: 10rem;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 0 -18rem;
        height: 600px;

        .content {
            background: rgba(0,0,0,.4);
            width: 1000px;
            margin: 0 auto;    
            margin-top: 12rem;

            .inner-content {
                padding: 1rem;
            }

            img {
                width: 300px;
            }
        }
    }

    @media screen and (max-width: 1320px) {
        .carousel-item {
            height: 500px;
            .content {
                margin-top: 5rem;
                width: 800px;
            }
        }
    }  
      
    @media screen and (max-width: 1100px) {
        display: none;
    }  
}

.booking-mobile {
    a {
        color: black;
        text-decoration: none;
    }
    background: #EEEEEEEE;
    padding: 2rem 0rem;
    text-align: center;
      
    @media screen and (min-width: 800px) {
        padding-top: 6rem;
    }  
      
    @media screen and (min-width: 1100px) {
        display: none;
    }  
}