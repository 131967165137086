
#home {
  #content {
    padding-top: 0rem;
  }

  @media screen and (max-width: 1258px) {
    .home_service_right {
      // max-width: 300px;
      margin: 0 auto;
    }    
  }

  @media screen and (max-width: 974px) {
    #content {
      padding-top: 5rem;
    }      

  }

  @media screen and (max-width: 650px) {
    #content {
      padding-top: 0rem;
    }      
   
  }

  
  @media screen and (max-width: 420px) {
    .home_service_right {
      img {
        min-width: 100px;
        max-width: 100%;
      }
    }
  }
}

// #home {
//   margin-top: -4rem;
//   padding-top: .1rem;
//   background-image: url("https://www.navette-privee-koffi.be/wp-content/uploads/2017/07/koffi-transports-monceau-sur-sambre.jpg"); //url("images/koffi-transports-monceau-sur-sambre.jpg");
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: 0 -25rem;
// }

.flex {
  display: flex;
}

  .content-width {
    margin: 0rem auto;
    max-width: 1200px;
    padding: 0rem 3rem;
  }


  // .booking {
  //   // background-color: red;
  //   margin-top: 20rem;
  // }

.home_service {
  font-family: 'Poppins';
  background: white;
  color: black;
  margin: 0;
  // margin-top: 10rem;

  .home_service_area {
    // margin: 0 auto;
    // width: 1200px;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;

    .home_service_left {
      max-width: 800px;
    }
  }

  h1 {
    color: #CA8E4C;
    font-weight: 100;
    margin: 0;
    padding: 0;
  }

  h2 {
    color: #111;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  p {
    color: #616161;
  }

  img {
    padding: 3rem 1rem;
  }

}
