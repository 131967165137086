/* Footer */
footer
{
    /*
    background:  url('images/ico_top.png') no-repeat top center, url('images/separateur.png') repeat-x top, url('images/ombre.png') repeat-x top;
    background-color: rgba(200,200,210,0.5);
    */
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // right: 0;
    background-color: #262831; //rgba(50,50,115,.6);
    color: white;
    font-size: .6em;
    width: 100%;
    margin: 0;
    padding: 0;

  .footer-blocks {
    display: flex;

    .footer-block {
      flex: 1;
      margin: 1rem;
      
      h2 {
        border-bottom: 1px solid #B69065;
        padding-bottom: 5px;
        width: fit-content;
      }

      p {
        line-height: 1.5rem;
        color: #898B92;
        font-size: 1.8em;
        font-weight: 400;

        &.footer-phone {
          color: white;
          font-weight: 800;
          font-size: 2.5em;
        }

        .footer-mail a {
          color: #D9AA70;
          text-decoration: none;

          &:hover {
            color: #A99A40;
            text-decoration: underline;
          }
        }
      }

      ul {
        padding: 0;

        li {
          list-style-type: none;
          margin: 1rem 0rem;
          font-size: 1.5em;

          a {
            color: #bbb;
            text-decoration: none;
          }
        }
      }
    }
  }

  & .footer-container
  {
      // width: 100%;
      flex-wrap: wrap;
      display: flex;
  }

  & .footer-container > p
  {
      min-width: 250px;
      margin: 0px 5px 5px 5px;
  }

  & #footer-container-left
  {
      flex: 1;
      order: 1;
  }

  & #footer-container-right
  {
      flex: 1;
      order: 2;
      text-align: right;
  }

  .icon {
    font-family: "simple-line-icons";
    &.phone-out::before {
      font-weight: 100;
      content: "\e046";
    }
    &.mail-open::before {
      content: "\e01e";
    }
  }

  #know-me {
    min-width: 400px;
  }

  #menu {
    max-width: 200px;
  }

  @media screen and (max-width: 920px) {
    .footer-blocks {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 547px) {

    // background-color: rgba(50,50,85,.8);
    & #footer-container-left, & #footer-container-right {
        text-align: center;
    }
    
  }    

  
  @media screen and (max-width: 450px) {

      .footer-blocks.content-width {
        padding: 0rem;
        margin: 0rem;
      }

    #know-me {
      min-width: 200px;
    }
    }
}
