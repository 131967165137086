
.track {
    // background: white;
    // text-align: left;
    // margin: -1rem;

    h3 {
        font-weight: 600;
        color: black;
        padding: 1rem 0rem;
        font-size: 1.6em;
}

    .track-boxes {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .track-box {
            font-family: 'Poppins';
            background-color: white;
            color: black;
            margin-bottom: 2rem;
            padding: .5rem;
            font-size: .8em;
            // height: 200px;
            
            .icon {
                font-size: 3em;
                // font-family: 'Merriweather';
                color: #D9AA72;
                &.user::before {
                    font-family: 'simple-line-icons';
                    content: "\e005";
                }
                &.watch::before {
                    font-family: 'line-icons';
                    content: "\e01d";
                }
                &.smile::before {
                    font-family: 'et-line';
                    content: "\e05b";
                }
                &.car::before {
                    font-family: 'font-awesome';
                    content: "\f1ba";
                }
                &.clock::before {
                    font-family: 'et-line';
                    content: "\e055";
                }
                }

            h4 {
                text-align: center;
                font-size: 1.8em;
                color: #555;
                padding: 0;
                margin: 0;
                font-weight: 100;
            }

            p {
                font-size: 1.1em;
                text-align: center;
                color: #666;
            }

            img {
                width: 100%;
            }
        }
    }

    
  @media screen and (max-width: 900px) {
      
    .track-box.flex2, .track-box.flex3 {
        width: 300px;
        margin: 1rem;
    }
  }

  
  @media screen and (max-width: 750px) {
      
    .track-box.flex2, .track-box.flex3 {
        width: 400px;
        margin: 1rem auto;
    }
  }

  
  @media screen and (min-width: 901px) {
    .track-box {
      &.flex3 {
        width: 30%;
      }

      &.flex2 {
          width: 47%;
      }
    }
  }
}